import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserManager, User } from 'oidc-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: User | null = null;
  firstName: String | null = null;
  isLoggedIn: boolean = false;
  private subscriptions = new Subscription();

  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.subscriptions.add(
      this.authService.getUserLoggedInEvent().subscribe(() => {
        this.loadUserInfo();
      })
    );
  }

  loadUserInfo(){
    console.log("Header"+this.isLoggedIn)
    this.firstName = this.authService.getUserInitial();
  }

}
