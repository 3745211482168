export const environment = {
    production: true,
    apiUrl:'https://casec-dev.tio.systems',
    oidc:{
        authority: 'https://access-dev.healthcare.elsevier.com/realms/h-pr-1016/.well-known/openid-configuration',
        clientId: 'casec',
        redirectUri: 'https://casec-dev.tio.systems/after-auth',
        responseType: 'code',
        scope: 'openid profile email',
        postLogoutRedirectUri: 'https://casec-dev.tio.systems/login',
        loadUserInfo: true
    }
};
