<header class="c-els-header ">
    <div class="c-els-header__branding">
        <div>
            <img class="c-els-header__logo" src="/assets/images/elsevier.png" alt="Elsevier Non Solus logo" />
        </div>
        <div>
            <h1 class="c-docs-2023-elsevier-orange u-els-font-size-h3">Comprehensive Application Security</h1>
        </div>
        <div class="userGreeting" *ngIf="firstName">
            <h4>Hello, {{firstName}}</h4>
        </div>
    </div>
</header>