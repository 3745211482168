import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AppService } from '../services/app.service';
import { ThreatModelRequest, ApplicationSpecification, ThreatModelResponse } from '../model/index' 

@Component({
  selector: 'app-threat-model',
  templateUrl: './threat-model.component.html',
  styleUrls: ['./threat-model.component.scss']
})
export class ThreatModelComponent {
  constructor(private formBuilder: FormBuilder,private service: AppService) { }

  systemInformationForm = this.formBuilder.group({
    sysDescription: ['',[Validators.required]],
    compliance: [['None'],[Validators.required]],
    authentication: ['None',[Validators.required]],
    applicationType: ['Web',[Validators.required]],
    sensitivity:['Public',[Validators.required]],
    role:['Public (No Roles)',[Validators.required]],
    securityControls: [['None'],[Validators.required]]
  });
  complianceValues: string[] = [
    'None'
    , 'GDPR - General Data Protection Regulation'
    , 'CCPA - California Consumer Privacy Act'
    , 'LGPD - Lei Geral de Proteção de Dados (Brazilian General Data Protection Law)'
    , 'FedRAMP - Federal Risk and Authorization Management Program'
    , 'TX-RAMP - Texas Risk and Authorization Management Program'
    , 'PIPL - Personal Information Protection Law (China)'
    , 'COPPA - Childrens Online Privacy Protection Act'
    , 'PCI DSS - Payment Card Industry Data Security Standard'
  ];
  authenticationValues = ['None'
    , 'Basic'
    , 'Form based'
    , 'API Key'
    , 'OAuth'
    , 'JWT (normally for authorization)'
    , 'Certificate based'];
  applicationTypeValues = ['Web', 'Mobile', 'Cloud', 'API', 'Standalone'];
  sensitivityValues = ['Public', 'Private', 'Confidential', 'Restricted'];
  roles = ['Public (No Roles)', 'User (Unprivileged)', 'Power User (Semi-Privileged)', 'Admin (Privileged)', 'Super Admin (Highly Privileged)'];
  securityControls: string[] = [
    'None',
    'Web Application Firewall',
    'Encryption at Rest',
    'Encryption in transit',
    'Multi-Factor Authentication',
    'Secure Access Service Edge',
    'Antivirus/Antimalware',
    'API Gateway',
    'Rate Limiting'
  ];

  threatModel:string = '';
  showLoadingDlg = false;
  securityRequirements:string = '';
  qeTestCases:string = '';
  showContentLoader = true;
  threatModelImprovements:string = '';
  securityRequirementsImprovements:string = '';
  qeTestCasesImprovements:string = '';

  getAppSpecifications(){
    if (!this.systemInformationForm.valid) {
      console.log("Invalid form, show error")
    }
    const appSpecs:ApplicationSpecification ={
      app_type: this.systemInformationForm.get('applicationType')?.value!,
      compliance: this.systemInformationForm.get('compliance')?.value!.toString(),
      auth_type: this.systemInformationForm.get('authentication')?.value!,
      sensitivity: this.systemInformationForm.get('sensitivity')?.value!,
      user_roles: this.systemInformationForm.get('role')?.value!,
      system_description: this.systemInformationForm.get('sysDescription')?.value!,
      security_controls: this.systemInformationForm.get('securityControls')?.value!.toString()
    }
    return appSpecs;
  }

  submitSystemInformation() {
    const request:ThreatModelRequest = {app_specs: this.getAppSpecifications() , model : "claude_3_sonnet"};
    this.showLoadingDlg = true;
    this.service.getThreatModel(request).then(response =>{
      this.showLoadingDlg = false;
      this.threatModel = response.output_text!;
      this.getSecurityRequirements(this.threatModel,false);
    });
  }

  getSecurityRequirements(threatModel:string,improvement:boolean){
    const request:ThreatModelRequest = {app_specs: this.getAppSpecifications() , model : "claude_3_sonnet",threat_model:threatModel};
    this.service.getSecurityRequirements(request).then(response =>{
      this.showLoadingDlg = false;
      if(improvement){
        this.securityRequirementsImprovements = response.output_text!;
      }else{
        this.securityRequirements = response.output_text!;
      }
      this.getQeTestCases(response.output_text!,improvement);
    });
  }

  getQeTestCases(securityRequirements:string,improvement:boolean){
    const request:ThreatModelRequest = {app_specs: this.getAppSpecifications() , model : "claude_3_sonnet",security_reqs:securityRequirements};
    this.service.getQeTestCases(request).then(response =>{
      this.showLoadingDlg = false;
      if(improvement){
        this.qeTestCasesImprovements = response.output_text!;
      }else{
        this.qeTestCases = response.output_text!;
      }
    });
  }

  validateThreatModel(){
    this.showLoadingDlg = true;
    const request:ThreatModelRequest = {app_specs: this.getAppSpecifications() , model : "gpt_4o",threat_model:this.threatModel};
    this.service.validateThreatModel(request).then(response =>{
      this.showLoadingDlg = false;
      this.threatModelImprovements = response.output_text!;
      this.getSecurityRequirements(this.threatModelImprovements,true);
    });
  }
}
