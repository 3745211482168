import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ThreatModelComponent } from './threat-model/threat-model.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { AfterAuthComponent } from './after-auth/after-auth.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch:'full'},
  { path: 'login', component: LoginComponent},
  { path: 'after-auth', component: AfterAuthComponent},
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'threat-model', component: ThreatModelComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
