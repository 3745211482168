import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { environment } from '../environments/environment';

const settings: UserManagerSettings = {
  authority: environment.oidc.authority, // Your OIDC provider URL
  client_id: environment.oidc.clientId,
  redirect_uri: environment.oidc.redirectUri, // Redirect URI
  response_type: environment.oidc.responseType,
  scope: environment.oidc.scope, // Scopes you need
  post_logout_redirect_uri: environment.oidc.postLogoutRedirectUri,
  loadUserInfo: environment.oidc.loadUserInfo
};

export const userManager = new UserManager(settings);