import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ThreatModelRequest, ThreatModelResponse } from '../model/index';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private httpClient: HttpClient) { }

  getApiEndpoint():string{
    return environment.apiUrl;
  }

  getThreatModel(postRequest:ThreatModelRequest): Promise<ThreatModelResponse> {
    let endPointUrl = this.getApiEndpoint() + "/prompting/threat_model";
    return this.httpClient
      .post<ThreatModelResponse>(endPointUrl, postRequest)
      .toPromise()
      .then((response: ThreatModelResponse | any) => {
        return response;
      });
  }

  
  getSecurityRequirements(postRequest:ThreatModelRequest): Promise<ThreatModelResponse> {
    let endPointUrl = this.getApiEndpoint() + "/prompting/security_requirements";
    return this.httpClient
      .post<ThreatModelResponse>(endPointUrl, postRequest)
      .toPromise()
      .then((response: ThreatModelResponse | any) => {
        return response;
      });
  }

  getQeTestCases(postRequest:ThreatModelRequest): Promise<ThreatModelResponse> {
    let endPointUrl = this.getApiEndpoint() + "/prompting/qe_testing";
    return this.httpClient
      .post<ThreatModelResponse>(endPointUrl, postRequest)
      .toPromise()
      .then((response: ThreatModelResponse | any) => {
        return response;
      });
  }

  validateThreatModel(postRequest:ThreatModelRequest): Promise<ThreatModelResponse> {
    let endPointUrl = this.getApiEndpoint() + "/prompting/threat_model/validate";
    return this.httpClient
      .post<ThreatModelResponse>(endPointUrl, postRequest)
      .toPromise()
      .then((response: ThreatModelResponse | any) => {
        return response;
      });
  }

}
