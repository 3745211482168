<main class="o-els-page-padding mainContent">
    <div class="c-els-card c-els-card--border-width-1o8 c-els-card--type-secondary" (click)="routeToThreatModelPage()">
        <div class="c-els-card__content">
            <b>SecAI</b>
            <p>{{secAI}}</p>
        </div>
    </div>

    <div class="c-els-card c-els-card--border-width-1o8 c-els-card--type-secondary">
        <div class="c-els-card__content">
            <b>Bowtie AI</b>
            <p>{{bowtieAI}}</p>
        </div>
    </div>

    <div class="c-els-card c-els-card--border-width-1o8 c-els-card--type-secondary">
        <div class="c-els-card__content">
            <b>Tabletop Security Coach</b>
            <p>{{tabletopSecurityCoach}}</p>
        </div>
    </div>
</main>