<footer id="footer" class="c-els-footer " role="contentinfo">
    <div>
        <a class="c-els-link c-els-link--image" href="https://www.elsevier.com/" target="_blank"
            rel="noopener noreferrer">
            <img class="logo c-els-footer__logo" src="/assets/images/elsevier.png" alt="Elsevier logo" />
        </a>
    </div>
    <div class="c-els-footer__content">
        <ul>
            <li class="c-els-footer__item">
                <a id="footer-terms-link" class="c-els-link c-els-link--navigation"
                    href="https://www.elsevier.com/legal/elsevier-website-terms-and-conditions" target="_blank"
                    rel="noopener noreferrer">Terms and conditions</a>
            </li>
            <li class="c-els-footer__item">
                <a id="footer-privacy-link" class="c-els-link c-els-link--navigation"
                    href="https://www.elsevier.com/legal/privacy-policy" target="_blank"
                    rel="noopener noreferrer">Privacy policy</a>
            </li>
            <li class="c-els-footer__item">
                <a id="footer-help-link" class="c-els-link c-els-link--navigation"
                    href="https://service.elsevier.com/app/home/supporthub/" target="_blank"
                    rel="noopener noreferrer">Help</a>
            </li>
        </ul>
        <p id="footer-cookie-disclaimer">We use cookies to help provide and enhance our service and tailor content. <a
                class="c-els-link c-els-link--inline" href="https://www.elsevier.com/legal/use-of-cookies"
                target="_blank" rel="noopener noreferrer">Cookie notice</a>.</p>
        <p id="footer-copyright">Copyright © 2024 Elsevier B.V. or its licensors and contributors. All rights are
            reserved, including those for text and data mining, AI training, and similar technologies. For all open
            access content, the Creative Commons licensing terms apply.</p>
    </div>
    <div class="c-els-footer__parent-logo">
        <a class="c-els-link c-els-link--image" href="http://www.relx.com/" target="_blank" rel="noopener noreferrer">
            <img src="assets/els/images/RelxLogo.svg" alt="RELX logo" />
        </a>
    </div>
</footer>