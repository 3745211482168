import { Injectable,EventEmitter } from '@angular/core';
import { UserManager, User } from 'oidc-client';
import { userManager } from '../auth-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userLoggedInEvent: EventEmitter<any> = new EventEmitter();
  private _userLoggedOutEvent: EventEmitter<any> = new EventEmitter();
  user: User | null = null;

  constructor() {
    this.getUser();
  }

  async login() {
    await userManager.signinRedirect();
  }

  logout() {
    userManager.signoutRedirect();
  }

  async getUser() {
    try {
      this.user = await userManager.getUser();
      if(this.user != null){
        console.log('Now got user data',this.user);
        this._userLoggedInEvent.emit(this.user);
      }
    } catch (error) {
      console.error('Error getting user:', error);
    }
  }

  async handleCallback() {
    this.user = await userManager.signinRedirectCallback();
  }

  isAuthenticated(): boolean {
    console.log(' isAuthenticated Now got user data',this.user);
    return this.user != null;
  }

  getAccessToken(): string | null {
    return this.user ? this.user.access_token : null;
  }

  getIdToken(): string | null {
    return this.user ? this.user.id_token : null;
  }


  currentUser(): User | null{
    return this.user;
  }

  getUserInitial(): string | null{
    console.log("initial"+this.user);
    if(this.user != null){
      const firstName = (this.user.profile.given_name || '').trim();
      return firstName;
    }else{
      return null;
    }
    
  }

  getUserLoggedInEvent(): EventEmitter<any> {
    return this._userLoggedInEvent;
  }

  getUserLoggedOutEvent(): EventEmitter<any> {
    return this._userLoggedOutEvent;
  }
}