<main class="o-els-page-padding mainContent">
    <form [formGroup]="systemInformationForm">
        <div class="o-els-flex-layout o-els-flex-layout--wrap@mobile u-els-background-color-n1">

            <div class="o-els-flex-layout__item u-els-width-1o2 u-els-width-1o1@mobile">
                <div class="o-els-container">
                    <div class="u-els-padding">
                        <div class="c-els-field ">
                            <label class="c-els-field__label" for="sample-id">
                                <span class="c-els-field__label-text">System Description, Requirement or Code</span>
                                <span class="c-els-field__wrap">
                                    <textarea class="c-els-field__input" formControlName="sysDescription" rows="15"
                                        data-testid="text-sysDescription" type="text"></textarea>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-els-flex-layout__item u-els-width-1o2 u-els-width-1o1@mobile">
                <div class="o-els-container">
                    <div class="u-els-padding">

                        <div class="c-els-field c-els-field--icon c-els-field--icon-right c-els-field--small">
                            <label class="c-els-field__label" for="sample-id">
                                <span class="c-els-field__label-text">Compliance and Legal Requirements</span>
                                <span class="c-els-field__wrap">
                                    <span class="c-els-field__icon-right">
                                        <svg aria-hidden="true" class="o-els-icon-svg o-els-icon-svg--1x1o2">
                                            <use xlink:href="#els-hmds-icon-chevron-down"></use>
                                        </svg>
                                    </span>
                                    <mat-select data-testid="select-compliance" formControlName="compliance" multiple class="c-els-field__input" >
                                        <mat-option *ngFor="let comp of complianceValues" [value]="comp">{{comp}}</mat-option>
                                    </mat-select>
                                </span>
                            </label>
                        </div>

                        <div
                            class="c-els-field c-els-field--icon c-els-field--icon-right c-els-field--small o-els-container--1o2">
                            <label class="c-els-field__label" for="sample-id">
                                <span class="c-els-field__label-text">Application Type</span>
                                <span class="c-els-field__wrap">
                                    <span class="c-els-field__icon-right">
                                        <svg aria-hidden="true" class="o-els-icon-svg o-els-icon-svg--1x1o2">
                                            <use xlink:href="#els-hmds-icon-chevron-down"></use>
                                        </svg>
                                    </span>
                                    <select data-testid="select-applicationType" formControlName="applicationType"
                                        class="c-els-field__input">
                                        <option *ngFor="let appType of applicationTypeValues" [ngValue]="appType">
                                            {{appType}}</option>
                                    </select>
                                </span>
                            </label>
                        </div>

                        <div
                            class="c-els-field c-els-field--icon c-els-field--icon-right c-els-field--small o-els-container--1o2">
                            <label class="c-els-field__label" for="sample-id">
                                <span class="c-els-field__label-text">Authentication Type</span>
                                <span class="c-els-field__wrap">
                                    <span class="c-els-field__icon-right">
                                        <svg aria-hidden="true" class="o-els-icon-svg o-els-icon-svg--1x1o2">
                                            <use xlink:href="#els-hmds-icon-chevron-down"></use>
                                        </svg>
                                    </span>
                                    <select data-testid="select-authentication" formControlName="authentication"
                                        class="c-els-field__input">
                                        <option *ngFor="let auth of authenticationValues" [ngValue]="auth">{{auth}}
                                        </option>
                                    </select>
                                </span>
                            </label>
                        </div>

                        <div
                            class="c-els-field c-els-field--icon c-els-field--icon-right c-els-field--small o-els-container--1o2">
                            <label class="c-els-field__label" for="sample-id">
                                <span class="c-els-field__label-text">Sensitivity</span>
                                <span class="c-els-field__wrap">
                                    <span class="c-els-field__icon-right">
                                        <svg aria-hidden="true" class="o-els-icon-svg o-els-icon-svg--1x1o2">
                                            <use xlink:href="#els-hmds-icon-chevron-down"></use>
                                        </svg>
                                    </span>
                                    <select data-testid="select-sensitivityType" formControlName="sensitivity"
                                        class="c-els-field__input">
                                        <option *ngFor="let sensitivity of sensitivityValues" [ngValue]="sensitivity">
                                            {{sensitivity}}</option>
                                    </select>
                                </span>
                            </label>
                        </div>

                        <div
                            class="c-els-field c-els-field--icon c-els-field--icon-right c-els-field--small o-els-container--1o2">
                            <label class="c-els-field__label" for="sample-id">
                                <span class="c-els-field__label-text">User roles</span>
                                <span class="c-els-field__wrap">
                                    <span class="c-els-field__icon-right">
                                        <svg aria-hidden="true" class="o-els-icon-svg o-els-icon-svg--1x1o2">
                                            <use xlink:href="#els-hmds-icon-chevron-down"></use>
                                        </svg>
                                    </span>
                                    <select data-testid="select-rolesType" formControlName="role"
                                        class="c-els-field__input">
                                        <option *ngFor="let role of roles" [ngValue]="role">
                                            {{role}}</option>
                                    </select>
                                </span>
                            </label>
                        </div>

                        <div
                            class="c-els-field c-els-field--icon c-els-field--icon-right c-els-field--small o-els-container--1o2">
                            <label class="c-els-field__label" for="sample-id">
                                <span class="c-els-field__label-text">Security Controls</span>
                                <span class="c-els-field__wrap">
                                    <span class="c-els-field__icon-right">
                                        <svg aria-hidden="true" class="o-els-icon-svg o-els-icon-svg--1x1o2">
                                            <use xlink:href="#els-hmds-icon-chevron-down"></use>
                                        </svg>
                                    </span>
                                    <mat-select formControlName="securityControls" multiple class="c-els-field__input" >
                                        <mat-option *ngFor="let securityControl of securityControls" [value]="securityControl">{{securityControl}}</mat-option>
                                    </mat-select>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="o-els-flex-layout o-els-flex-layout--gutters">
            <div class="o-els-flex-layout__item o-els-flex-layout__item--grow">
                <div class="u-els-padding u-els-background-color-n1 u-els-text-left">
                    <button data-testid="button-submit" type="button" class="c-els-button c-els-button--small"
                        [accessKey]="'S'" [disabled]="!systemInformationForm.valid" (click)="submitSystemInformation()">
                        <u>S</u>ubmit
                    </button>
                </div>
            </div>
        </div>
    </form>


    <div *ngIf="showLoadingDlg" class="loadingOverlay">
        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="42" class=" c-els-progress-loader--no-percentage"
            aria-label="In progress">
            <div class="c-els-progress-loader__inner">
            </div>
        </div>
    </div>
    <div class="o-els-container u-els-background-color-n1" *ngIf="threatModel">
        <div class="u-els-padding">
            <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="left">
                <mat-tab label="Threat Model">
                    <div class="u-els-padding contentOutput">
                        <div [innerHtml]="threatModel"></div>
                    </div>
                </mat-tab>
                <mat-tab label="Security Requirements" *ngIf="securityRequirements">
                    <div class="u-els-padding contentOutput">
                        <div [innerHtml]="securityRequirements"></div>
                    </div>
                </mat-tab>
                <mat-tab label="QE Test Cases" *ngIf="qeTestCases">
                    <div class="u-els-padding contentOutput">
                        <div [innerHtml]="qeTestCases"></div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

    <div class="validateLink" *ngIf="threatModel && securityRequirements && qeTestCases">
        <h3><a data-testid="validate" (click)="validateThreatModel()" class="c-els-link"><u>V</u>alidate above threat model</a></h3>
    </div>
    

    <div class="o-els-container u-els-background-color-n1" *ngIf="threatModelImprovements">
        <div class="u-els-padding">
            <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="left">
                <mat-tab label="Threat Model">
                    <div class="u-els-padding contentOutput">
                        <div [innerHtml]="threatModelImprovements"></div>
                    </div>
                </mat-tab>
                <mat-tab label="Security Requirements" *ngIf="securityRequirementsImprovements">
                    <div class="u-els-padding contentOutput">
                        <div [innerHtml]="securityRequirementsImprovements"></div>
                    </div>
                </mat-tab>
                <mat-tab label="QE Test Cases" *ngIf="qeTestCasesImprovements">
                    <div class="u-els-padding contentOutput">
                        <div [innerHtml]="qeTestCasesImprovements"></div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

</main>