import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent{
  constructor(private router: Router) { }
  secAI =  'Your AI-powered security assistant, generating comprehensive threat models, security requirements, and QA test cases.'
  bowtieAI = 'Comprehensive evaluation of potential risks and their potential impact on organizational assets.';
  tabletopSecurityCoach = 'Tabletop Security Coach is an interactive tool designed to guide security teams through tabletop exercises, enabling them to simulate and respond to various security scenarios.';

  routeToThreatModelPage(){
    console.log("Routing to page");
    this.router.navigate(['/threat-model']);
  }
  
}

